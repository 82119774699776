<template>
  <div>
    <titleBar
      v-if="isSettingsHome"
      :title="'Settings'"
      :img="user?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <settingsTabs />

    <div class="section" v-if="user && user.handle">
      <div class="container w800">
        <h2 class="title is-4">About you</h2>
        <!-- 
        <headerManage
          title="Profile SEPARATE"
          sub="Edit your profile"
          btLabel="Edit"
          :btTo="'/edit-profile'"
          @save="save"
          :posting="posting"
        >
     
          <div>
            <p>This is some custom content for the slot.</p>
          </div>
        </headerManage>-->

        <headerManage
          title="Profile"
          sub="Edit your public profile"
          desc="Your public profile. "
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontalNO label="Bio">
              <b-input
                type="textarea"
                placeholder="Hi!  I chat and I know things."
                v-model="user.bio"
                :loading="posting"
                :disabled="posting"
              ></b-input>
            </b-field>

            <b-field horizontalNO label=" Name   ">
              <b-input
                size="is-"
                placeholder="Samantha (bots will call you that)"
                v-model="user.name"
                :loading="posting"
                :disabled="posting"
              ></b-input>
            </b-field>

            <b-field horizontalNO label="Website">
              <b-input
                size="is-"
                placeholder="https://myapp.com/developpers"
                v-model="user.website"
                :loading="posting"
                :disabled="posting"
                type="url"
              ></b-input>
            </b-field>

            <b-field horizontalNO label="Avatar URL ">
              <b-input
                size="is-"
                placeholder="https://myapp.com/img/logo.png"
                v-model="user.avatar"
                :loading="posting"
                :disabled="posting"
                type="url"
              ></b-input>
            </b-field>
            <b-field horizontalNO v-if="user.avatar">
              <img :src="user.avatar" style="width: 150px; height: 150px; border-radius: 50%" />
            </b-field>
          </div>
        </headerManage>
        <!--  
        <headerManage
          title="Personality"
          :sub="user.personality ? user.personality : 'Not set'"
          desc="Overarching personality of your bot, visible accross all features. "
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontalNo label="">
              <b-input
                type="textarea"
                placeholder="A British farmer from the 18th century. Has 12 kids. Interested in chickens and the weather. "
                v-model="user.personality"
                :loading="posting"
                :disabled="posting"
              ></b-input>
            </b-field>
            <OtherComponent />
          </div>
        </headerManage>-->

        <headerManage
          title="Context infos"
          :sub="user.contextText ? user.contextText : 'Not set'"
          desc="  Bots will personalize their responses using this data.
            This isn't visible on your profile."
          @save="save"
          :posting="posting"
        >
          <!-- 

           Personalize AI responses using custom data.  
           Adjust it or add more details anytime. 
           This isn't visible on your profile.

           Bots will personalize their responses using this data.
            This isn't visible on your profile.

        -->
          <div>
            <b-field horizontalNo label="">
              <b-input
                type="textarea"
                placeholder="My name is Jane. I am 88 years old. I live in Cambodia. I am a Japanese citizen. I was a math teacher. I love animals. I'm very emotional and sensitive."
                v-model="user.contextText"
                :loading="posting"
                :disabled="posting"
              ></b-input>
            </b-field>

            <b-field horizontalNO :label="c.label" v-for="c in cons" :key="c.label">
              <b-input
                size="is-"
                :placeholder="c.placeholder"
                v-model="user.profile[c.name]"
                :loading="posting"
                :disabled="posting"
                :type="c.type || 'text'"
              ></b-input>
            </b-field>
          </div>
        </headerManage>

        <h2 class="title is-4">Access</h2>

        <headerManage
          title="Email"
          :sub="user.email ? user.email : 'Not set'"
          desc="Your email address. You can't change it."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field horizontalNO label="">
              <b-input
                size="is-"
                placeholder="

                "
                :value="user.email"
                :loading="posting"
                :disabled="true"
                type="email"
              ></b-input>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Change password"
          sub="Change your password"
          desc="Change your password. 8+ chars"
          @save="saveNewPass"
          :posting="posting"
        >
          <div>
            <b-field>
              <b-input
                size="is-"
                placeholder="new secret password"
                v-model="pass"
                :loading="posting"
                :disabled="posting"
                type="password"
              ></b-input>
            </b-field>
          </div>
        </headerManage>

        <h2 class="title is-4">Billing</h2>

        <headerManage title="Credit card" sub="Not set" btLabel="Edit" @save="save" :posting="posting">
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
              >Save changes</b-button
            >
          </div>
        </headerManage>

        <headerManage title="Auto-recharge wallet" sub="Not set" btLabel="Edit" @save="save" :posting="posting">
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
              >Save changes</b-button
            >
          </div>
        </headerManage>

        <h2 class="title is-4">Danger zone</h2>

        <headerManage
          title="Delete account  "
          sub="Delete your account and all your data "
          desc="This action is irreversible. You will not be able to recover your data. Don't attempt to game the system, we will find out."
          btLabel="Delete"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-button type="is-danger is-outlined" @click="save" :loading="posting" :disabled="posting"
              >Delete my account and all my data</b-button
            >
          </div>
        </headerManage>

        <!-- -->
      </div>
    </div>

    <div class="section" :class="{ isSettingsHome: isSettingsHome, notHome: !isSettingsHome }">
      <div class="container w1200" v-if="user">
        <div class="columns is-variable is-8">
          <div class="column is-narrow zone list">
            <jsonDebug :data="user" label="user" style="max-width: 500px" />

            <div class="intro-card" style="text-align: center">
              <div class="message-avatar">
                <img :src="user?.avatar" alt="" class="avatar" />
              </div>

              <div class="message-handle">
                <h2 class="title is-6" style="margin-bottom: 4px">{{ user?.handle }} Settings</h2>

                <router-link :to="`/u/${user.handle}`" class="button is-light is-small is-rounded is-hidden-touch">
                  View Profile
                </router-link>
              </div>
            </div>

            <!--        MENU! Loop over menu, router links buttons -->

            <br />
            <div class="menuSettings">
              <div v-for="i in menu" :key="i.to">
                <router-link :to="i.to" class="button is-outlined is-dark is-fullwidth" style="margin-bottom: 10px">
                  {{ i.label }}
                </router-link>
              </div>
            </div>

            <div class="is-hidden-touch">
              <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
                >Save changes</b-button
              >
              <b-button type="is-danger is-outlined" @click="resetChanges" :loading="posting" :disabled="posting"
                >Cancel changes</b-button
              >
            </div>
          </div>
          <div class="column zone convo">
            <router-view :user="user" :handle="handle" v-if="user" @save="save"></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonDebug from "@/components/e/jsonDebug.vue";
import inboxList from "@/components/inbox/InboxList.vue";
import inboxThread from "@/components/inbox/inboxThread.vue";

import settingsTabs from "@/components/nav/settingsTabs.vue";
import headerManage from "@/components/studio/headerManage";
export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  name: "BotSettings",
  components: {
    settingsTabs,
    headerManage,
    // inboxList,
    //inboxThread,
    //  JsonDebugt,
  },
  data() {
    return {
      // featuredTags,
      interlocutors: [],
      convos: [],
      convo: null,
      tasks: [],
      user: null,
      //keyFeatures,
      // projs: [],
      loading: true,
      posting: false,
      pass: "",
      // loadingOps: true,
    };
  },
  mounted() {
    this.loadProfile();
  },
  watch: {
    $route(to, from) {
      //  this.loadThread(to.params.userId, to.params.convo);
    },
  },
  methods: {
    saveNewPass() {
      var d = this.user;
      var pass = this.pass;
      window.API.updatePassword(d.handle, { pass: pass })
        .then((bot) => {
          this.posting = false;
          //snackbar black buefy
          this.$buefy.snackbar.open({
            message: "Password updated!",
            type: "is-success",
            position: "is-bottom-right",
            actionText: "Close",
            indefinite: false,
          });
          this.pass = "";

          //bot updated!
        })
        .catch((err) => {
          this.posting = false;
          //snackbar black buefy
          this.$buefy.snackbar.open({
            message: "Error updating password, make sure it's at least 8 characters long.",
            type: "is-danger",
            position: "is-bottom-right",
            actionText: "Close",
            indefinite: false,
          });
        });
    },
    loadProfile(index) {
      var id = this.handle; //this.$route.params.userId;

      var opt = {};
      // if is admin, add moderator option.
      /*
      if (this.$store.main.getters.isAdmin) {
        opt = { moderator: true };
      }*/

      var isAdmin = this.$store.main.getters.isAdmin;
      var method = isAdmin ? "userProfileAdmin" : "userProfileOwner";
      window.API[method](id, opt).then((b) => {
        this.user = b;
        this.loading = false;
        // console.log("PROJJ!", proj, proj.id);
      });
    },
    resetChanges() {
      this.loadProfile();
    },
    save: function (cb) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.user;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };

      let method;
      //ad is admin
      if (this.$route.name == "UserSettingsAdmin" && this.$store.main.getters.isAdmin) {
        method = "updateUserAdmin"; //PUT, for admins only
      } else {
        method = "updateUser";
      }

      window.API[method](d.handle, d).then((bot) => {
        this.posting = false;
        //snackbar black buefy
        this.$buefy.snackbar.open({
          message: "Changes saved",
          type: "is-success",
          position: "is-bottom-right",
          actionText: "Close",
          indefinite: false,
        });

        //bot updated!
        if (cb) cb();
      });
    },
  },
  computed: {
    cons() {
      //legacy context , itemized
      /*
 NAME: user.handle || user.handle,
    AGE: user.profile?.age || 30,
    GENDER: user.profile?.gender || "male",
    USER_COUNTRY: user.profile?.country || ctx.userCountry || "US",
    PASSPORT_COUNTRY:
      user.profile?.passportCountry || user.profile?.country || ctx.userCountry,
   */

      const cons = [
        {
          name: "name",
          label: "Name",
          placeholder: "ex: Jane",
          type: "text",
        },
        {
          name: "age",
          label: "Age",
          placeholder: "ex: 88",
          type: "text",
        },
        {
          name: "country",
          label: "Country",
          placeholder: "ex: Thailand",
          type: "dropdown",
        },
        /*{
          name: "gender",
          label: "Gender",
          placeholder: "Your gender",
          type: "dropdown",
        },*/
        {
          name: "passportCountry",
          label: "Passport",
          info: "For travel advices",
          placeholder: "ex: Japanese",
          type: "text",
        },
      ];

      var ccc = cons.map((c) => {
        // c.contextVars = this.user[c.name];
        return c;
      });
      return ccc;
    },

    menu() {
      var h = this.handle; //this.$route.params.userId;
      const base = "/u/" + h + "/settings/";
      var menu = [
        {
          label: "Edit Profile",
          icon: "fal fa-pencil",
          to: base + "profile",
        },

        {
          label: "Context info",
          icon: "fal fa-analytics",
          to: base + "context",
        },

        {
          label: "Change password",
          icon: "fal fa-key",
          to: base + "pass",
        },

        {
          label: "Create a bot",
          icon: "fal fa-analytics",
          to: "/creators",
          //  to: base + "create",
        },

        /*


        {
          label: "Monetization",
          icon: "fal fa-lock-open",
          to: base + "monetization",
        },

        {
          label: "My bots",
          icon: "fal fa-robot",
          to: base + "messaging",
        },




        {
          label: "Gallery",
          icon: "fal fa-photo",
          to: base + "gallery",
        },

        //
        {
          label: "Edit Readme",
          icon: "fal fa-file",
          to: base + "readme",
        },
*/
        {
          label: "Mod / Admin",
          icon: "fal fa-wrench",
          to: base + "admin",
          isAdmin: true,
        },
      ];
      // filter out admin only items
      menu = menu.filter((m) => {
        if (m.isAdmin && !this.$store.main.getters.isAdmin) return false;
        return true;
      });

      return menu;
    },
    isSettingsHome() {
      return (
        this.$route.name === "UserSettings"
        //this.$route.name === "BotSettingsHome" ||
        //this.$route.name === "BotSettingsEmpty"
      );
    },
    isNewMessage() {
      return this.$route.name === "inboxNewConvo" || this.$route.name === "inboxNewConvoTask";
    },
    interlocutorsShown() {
      //filter and add some props like "active., typing, etc..."
      return this.interlocutors;
      // Return a list of interlocutors here based on some state or props
    },

    newRoute() {
      return this.isNewMessage;
    },
    isMobile() {
      return true;
      // Return a boolean indicating whether the user is on a mobile device
    },
    handle() {
      return this.$route.params.userId || this.user?.handle || this.$store.main.getters.userId;
    },
    titleBarTitle() {
      if (this.isSettingsHome) return "Chats";
      // if (this.isNewMessage) return this.$route.params.userId + " ✨ ";
      var rb = this.handle;
      if (rb) return rb;
      return ""; //prevent flickering, but let user go back while slow load.
      // if (this.user) return this.user.handle;
      ///var rb = this.$route.params.userId;
    },
    title() {
      return this.titleBarTitle + " / DM";
    },
  },
};
</script>

<style scoped>
h2 {
  margin-top: 50px;
}
.zone {
  /* 
  border-radius: 50px;
  background-color: white;
  border: 1px #ccc solid;*/
}

.zone.list {
  /*
  border-radius: 50px;
   background-color: white;
  */
}

/* mobiel selective columns depending on class isSettingsHome using media query*/

.inboxList {
  max-width: 300px;
}
@media only screen and (max-width: 1022px) {
  .isSettingsHome .zone.convo {
    display: none;
  }
  .notHome .zone.list {
    display: none;
  }
  .inboxList {
    max-width: 999px;
  }
}

img.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
}
</style>
